import React from "react";
import styles from "./FullPageLoader.module.scss";
import Spinner from "react-bootstrap/Spinner";

export default function FullPageLoader() {
    return <div className={styles.wrap}>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    </div>;
}
