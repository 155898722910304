import React from "react";
import ReactDOM from "react-dom";

import "./sass/base.scss";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <App/>,
    window.document.getElementById("app")
);

serviceWorker.unregister();
