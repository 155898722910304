import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import FullPageLoader from "./components/Generic/Loaders/FullPageLoader";

const GetFundedPage = lazy(() => import("./pages/getfunded"));
const CandidateIngestionPage = lazy(() => import("./pages/candidate_ingestion"));
const BenchIngestionPage = lazy(() => import("./pages/bench_ingestion"));

const PageNotFound = lazy(() => import("./components/Generic/PageNotFound/PageNotFound"));

function App() {
    return <BrowserRouter>
        <Suspense fallback={<FullPageLoader/>}>
            <Routes>
                {window.location.hostname.startsWith('apply')
                    && <Route path="/forms" element={<CandidateIngestionPage/>}>
                    <Route path=":token" element={<CandidateIngestionPage/>}/>
                </Route>}
                {window.location.hostname.startsWith('bench')
                    && <Route path="/forms" element={<BenchIngestionPage/>}>
                    <Route path=":token" element={<BenchIngestionPage/>}/>
                </Route>}
                {window.location.hostname.startsWith('getfunded')
                    && <Route path="/forms" element={<GetFundedPage/>}>
                    <Route path=":token" element={<GetFundedPage/>}/>
                </Route>}
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </Suspense>
    </BrowserRouter>;
}

export default App;
